import React, { useEffect, useMemo, useState } from 'react';
import { Empty, Skeleton } from 'antd';
import PageHeader from '../../components/PageHeader';
import { Container, Grid, Typography } from '@mui/material';
import { useGetMyCdas } from '../../queries/CDA/user';
import { Table, UserListRow, UserTableHeader } from '../Admin/styles';
import { generatePath } from 'react-router-dom';
import RouteConstants from '../../routes';
import { Link } from '../../components/Clicks';
import StatusChip from '../../components/CDA/chip';
import { CDAsListRow, CDAsTableHeader } from './style';

interface ICdasListProps {
  filter?: object;
}

const CdasList: React.FC<ICdasListProps> = () => {
  const { data: CDAs } = useGetMyCdas();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (CDAs) {
      setIsLoading(false);
    }
  }, [CDAs]);

  const tableRows = useMemo(() => {
    return CDAs?.map(cda => (
      <CDAsListRow
        key={`cda-${cda.id}`}
        to={generatePath(RouteConstants.cda.edit, {
          cdaId: cda.id,
          isEdit: true,
        })}
        loading={isLoading ? 'true' : 'false'}
      >
        <Typography>
          {cda.address
            ? `${cda.address.streetAddress}, ${cda.address.city}, ${cda.address.state} ${cda.address.zipCode}`
            : 'No address yet'}
        </Typography>
        <Typography>
          <StatusChip status={cda.status} />
        </Typography>
      </CDAsListRow>
    ));
  }, [CDAs, isLoading]);

  return (
    <Container maxWidth="xl" sx={{ pb: 6 }}>
      <PageHeader
        title={'CDAs'}
        subtitle={
          <Grid container>
            <Grid item xs={8}>
              <Typography variant={'subtitle1'}>
                Some explanation about the process.
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end">
              <Link to={RouteConstants.cda.create} common={{ small: 'true' }}>
                Fill new CDA
              </Link>
            </Grid>
          </Grid>
        }
        withDivider
      />
      <Table>
        <CDAsTableHeader>
          <Typography variant="caption">Address</Typography>
          <Typography variant="caption">Status</Typography>
        </CDAsTableHeader>
        {isLoading ? (
          <Skeleton active style={{ padding: '1.6rem 2.4rem' }} />
        ) : CDAs && CDAs?.length > 0 ? (
          tableRows
        ) : (
          <Empty
            description="No CDAs yet"
            style={{ padding: '1.6rem 2.4rem' }}
          />
        )}
      </Table>
    </Container>
  );
};

export default CdasList;
