import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from '../../../styles/media';

interface IRowProps {
  loading: 'true' | 'false';
}

const blur = keyframes`
  0%,
  90% {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
  }
  50% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
  }
`;

export const CDAsListRow = styled(Link)<IRowProps>`
  display: grid;
  grid-template-columns: 80% 20%;
  grid-gap: 3rem;
  align-items: center;
  padding: 0 2.4rem;

  ${props =>
    props.loading === 'true'
      ? css`
          -webkit-filter: blur(2px);
          -moz-filter: blur(2px);
          -o-filter: blur(2px);
          -ms-filter: blur(2px);
          animation: ${blur} 1.5s ease 0s infinite;
        `
      : ''};

  &:hover {
    background-color: ${props =>
      props.loading === 'true' ? 'transparent' : '#fafafa'};
  }

  ${media.phone`
    grid-template-columns: 10rem 1fr;
  `}
`;

export const CDAsTableHeader = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  grid-gap: 3rem;
  align-items: center;
  padding: 1.6rem 0 1.6rem 2.4rem;

  ${media.phone`
    grid-template-columns: 10rem 1fr;
  `}
`;
