import React, { ChangeEvent, useEffect, useState } from 'react';
import { useGetCda } from '../../../../queries/CDA/user';
import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import CDAEditForm from '../../../../components/CDA/editForm';
import { LoadingButton } from '@mui/lab';
import {
  useGetCdaAdmin,
  useRequestCdaChanges,
  useSignCda,
  useUnsignCda,
} from '../../../../queries/CDA/admin';
import axios from 'axios';
import { API_URL } from '../../../../../utils/constants';
import { ControlPointOutlined } from '@mui/icons-material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';

interface ICdaCreateEditProps {
  isEdit?: boolean;
  filter?: object;
}

interface ICCs {
  ccs: {
    email: string;
  }[];
}

const AdminCdaEdit: React.FC<ICdaCreateEditProps> = () => {
  const { cdaId } = useParams<{ cdaId: string }>();
  const { data: cda, error } = useGetCdaAdmin(cdaId, {
    options: {
      refetchOnWindowFocus: false,
    },
  });
  const [isSigning, setIsSigning] = useState(false);

  const signMutation = useSignCda(cdaId);
  const unsignMutation = useUnsignCda(cdaId);
  const requestChangesMutation = useRequestCdaChanges();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isSigningModalOpen, setSigningModalOpen] = useState(false);
  const [textareaContent, setTextareaContent] = useState('');

  const handleChange = event => {
    setTextareaContent(event.target.value);
  };

  const handleSubmit = async event => {
    if (cda) {
      event.preventDefault();

      requestChangesMutation.mutate(
        { cdaId: cdaId, changes: textareaContent },
        {
          onSuccess: data => {
            closeModal();
            cda.status = data.status;
          },
        },
      );
    }
  };

  const handleCCFormSubmit = data => {
    if (cda) {
      setIsSigning(true);

      signMutation.mutate(
        {
          cdaId: cdaId,
          ccs: data,
        },
        {
          onSuccess: data => {
            setIsSigning(false);
            cda.signingDate = data.signingDate;
            cda.status = data.status;
            closeModal();
          },
        },
      );
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSigningModalOpen(false);
  };

  const openSigningModal = () => {
    setSigningModalOpen(true);
  };

  const {
    control: ccControl,
    handleSubmit: ccHandleSubmit,
    formState: { errors },
  } = useForm<ICCs>({
    defaultValues: {
      ccs: [],
    },
  });

  const {
    fields: ccField,
    append: ccAppend,
    remove: ccRemove,
  } = useFieldArray({
    control: ccControl,
    name: 'ccs',
  });

  const removeSignature = () => {
    if (cda) {
      setIsSigning(true);

      unsignMutation.mutate(
        { cdaId: cdaId },
        {
          onSuccess: data => {
            setIsSigning(false);
            cda.signingDate = data.signingDate;
            cda.status = data.status;
          },
        },
      );
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return cda ? (
    <Grid>
      <Grid container justifyContent="space-between">
        <Grid item>Status: {cda.status}</Grid>
        <Grid item>
          {cda.signingDate ? (
            <LoadingButton
              loading={isSigning}
              variant={'outlined'}
              onClick={removeSignature}
            >
              Remove signature
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={isSigning}
              variant={'outlined'}
              onClick={openSigningModal}
            >
              Sign
            </LoadingButton>
          )}
        </Grid>
        <Grid item>
          <Button variant={'outlined'} onClick={openModal}>
            Request changes
          </Button>
        </Grid>
        <Grid item>
          <Button variant={'outlined'} href={cda.pdfUrl} target={'_blank'}>
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <CDAEditForm cda={cda} onMutationSuccess={() => {}} isAdmin={true} />
        </Grid>
      </Grid>
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '80%',
            maxWidth: '600px',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Request Changes</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextareaAutosize
              aria-label="description"
              placeholder="Describe the changes..."
              style={{ width: '100%', height: '100px' }}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button color="primary" variant={'contained'} type="submit">
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={isSigningModalOpen}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '80%',
            maxWidth: '600px',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          Confirmation will be sent to the Title company and to the agent. Is
          there anyone else you would like to add to CC?
        </DialogTitle>
        <form onSubmit={ccHandleSubmit(handleCCFormSubmit)}>
          <DialogContent>
            {ccField.map((cc, index) => (
              <Grid container item xs={12} spacing={2} mb={2}>
                <Grid item xs={12}>
                  <Controller
                    name={`ccs[${index}].email` as any}
                    control={ccControl}
                    rules={{ pattern: /^\S+@\S+$/i }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        error={!!errors?.ccs?.[index]}
                        helperText={errors?.ccs?.[index]?.message}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() => ccRemove(index)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
            <Button
              startIcon={<ControlPointOutlined />}
              onClick={() => ccAppend({ email: '' })}
              style={{ display: 'flex', margin: 'auto' }}
            >
              Add CC
            </Button>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <LoadingButton
              color="primary"
              variant={'contained'}
              type="submit"
              loading={isSigning}
            >
              Sign
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Grid>
  ) : (
    <div>Loading...</div>
  );
};

export default AdminCdaEdit;
