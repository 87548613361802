import React, { useEffect, useMemo, useState } from 'react';
import {
  IAddress,
  IBankInstruction,
  ICDA,
  IPayout,
  ISettlementCharge,
  ISpecialInstruction,
  ITitleCompany,
} from '../../queries/CDA/type';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Slide,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  useCreateCda,
  useSubmitCda,
  useUpdateCda,
} from '../../queries/CDA/user';
import { useSnackBar } from '../CustomSnackbar';
import RouteConstants from '../../routes';
import { generatePath, useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import useResponsive from '../../hooks/useResponsive';
import { DatePickerInput, InputDropdown } from '../FormInputs';
import { LicenseStates } from '../../containers/UserSettings/States';
import { ControlPointOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../containers/UserSettings/slice/selectors';
import { useUpdateCdaAdmin } from '../../queries/CDA/admin';

const CDAEditForm: React.FC<{
  cda: ICDA | undefined;
  onMutationSuccess;
  isAdmin;
}> = ({ cda, onMutationSuccess, isAdmin }) => {
  const [saving, setSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(0);
  const updateCDA = useUpdateCda();
  const updateCDAadmin = useUpdateCdaAdmin();
  const createCda = useCreateCda();
  const submitCda = useSubmitCda();
  const [pdfUrl, setPdfUrl] = useState(cda ? cda.pdfUrl : '');

  const { handleSubmit, control, reset, watch, setValue } = useForm<ICDA>({
    defaultValues: {
      state: '',
      titleCompany: {
        name: '',
        contact: '',
        phone: '',
        email: '',
      },
      address: {
        streetAddress: '',
        state: '',
        zipCode: '',
        city: '',
      },
      closingDate: null,
      salePrice: '',
      seller: '',
      type: '',
      listingAgent: '',
      buyer: '',
      settlementCharges: [
        {
          chargeTo: '',
          forService: 'Sale Commission',
          amount: '',
        },
      ],
      payouts: [
        {
          payTo: '',
          amount: '',
        },
      ],
      bankInstructions: [
        {
          bankName: 'Chase',
          payeeName: '',
          routingNumber: '111000614',
          accountNumber: '759336362',
        },
      ],
      specialInstructions: [
        {
          instruction: '',
        },
      ],
    },
  });

  const selectedState = watch('address.state');
  const cdaId = watch('id');

  useEffect(() => {
    if (!cda) {
      setValue(
        'bankInstructions[0].payeeName' as any,
        getStateEntity(selectedState),
      );
    }
  }, [selectedState, setValue]);

  const {
    fields: settlementChargesFields,
    append: settlementChargesAppend,
    remove: settlementChargesRemove,
  } = useFieldArray({
    control,
    name: 'settlementCharges',
  });

  const {
    fields: payoutsFields,
    append: payoutsAppend,
    remove: payoutsRemove,
  } = useFieldArray({
    control,
    name: 'payouts',
  });

  const {
    fields: bankInstructionsFields,
    append: bankInstructionsAppend,
    remove: bankInstructionsRemove,
  } = useFieldArray({
    control,
    name: 'bankInstructions',
  });

  const {
    fields: specialInstructionsFields,
    append: specialInstructionsAppend,
    remove: specialInstructionsRemove,
  } = useFieldArray({
    control,
    name: 'specialInstructions',
  });

  const nextStep = () => {
    setPreviousStep(currentStep);
    setCurrentStep(prev => prev + 1);
  };

  const prevStep = () => {
    setPreviousStep(currentStep);
    setCurrentStep(prev => prev - 1);
  };

  useEffect(() => {
    if (cda) {
      reset({ ...cda });
    }
  }, [cda]);

  const onSubmit = data => {
    setSaving(true);

    if (data.id) {
      if (isAdmin) {
        updateCDAadmin.mutate(data, {
          onSuccess: response => {
            setSaving(false);
            setPdfUrl(response.pdfUrl + '?timestamp=' + Date.now());
            nextStep();
          },
        });
      } else {
        updateCDA.mutate(data, {
          onSuccess: response => {
            setSaving(false);
            setPdfUrl(response.pdfUrl + '?timestamp=' + Date.now());
            nextStep();
          },
        });
      }
    } else {
      createCda.mutate(data, {
        onSuccess: response => {
          setSaving(false);
          setPdfUrl(response.pdfUrl + '?timestamp=' + Date.now());
          setValue('id', response.id);
          nextStep();
        },
      });
    }
  };

  const submitCDA = () => {
    setSaving(true);
    submitCda.mutate(cdaId, {
      onSuccess: response => {
        setSaving(false);
        nextStep();
      },
    });
  };

  const getStateAddress = selectedState => {
    switch (selectedState) {
      case 'FL':
        return '777 Brickell Ave #500-94761, Miami, FL 33131';
      case 'LA':
        return '717 Crockett St #201, Shreveport, LA 71101 ';
      case 'CA':
        return '2443 Fillmore St #380-6638, San Francisco, CA 94115';
      case 'AR':
        return '3824 Cedar Springs Rd #801-7724, Dallas, TX 75219';
      case 'NC':
        return '500 Westover Dr #30061, Sanford, NC 27330';
      case 'TX':
      default:
        return '3824 Cedar Springs Rd #801-7724, Dallas, TX 75219';
    }
  };

  const getStateEntity = selectedState => {
    switch (selectedState) {
      case 'CA':
        return 'PR Advisors Inc';
      default:
        return 'PR Advisors, LLC';
    }
  };

  return !cda || cda.status !== 'signed' || isAdmin ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ width: '800px' }}
      >
        <Grid item xs={4}>
          <Stepper activeStep={currentStep} orientation="vertical">
            <Step key={'1'}>
              <StepLabel>Deal Information</StepLabel>
            </Step>
            <Step key={'2'}>
              <StepLabel>Title Company</StepLabel>
            </Step>
            <Step key={'3'}>
              <StepLabel>Settlement Charges</StepLabel>
            </Step>
            <Step key={'4'}>
              <StepLabel>Payouts</StepLabel>
            </Step>
            <Step key={'5'}>
              <StepLabel>Bank Instructions</StepLabel>
            </Step>
            <Step key={'6'}>
              <StepLabel>Special Instructions</StepLabel>
            </Step>
            <Step key={'7'}>
              <StepLabel>CDA Preview</StepLabel>
            </Step>
          </Stepper>
        </Grid>
        <Grid item xs={8} mt={5}>
          <Slide
            in={currentStep === 0}
            direction={previousStep < currentStep ? 'up' : 'down'}
          >
            <Box style={{ display: currentStep === 0 ? 'block' : 'none' }}>
              <Card style={{ padding: '15px', margin: '15px' }}>
                <Typography mb={2}>
                  Please provide information about the property.
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="address.streetAddress"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Address" fullWidth />
                      )}
                    />
                  </Grid>

                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={4}>
                      <Controller
                        name="address.city"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="City" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputDropdown
                        control={control}
                        name={'address.state'}
                        label={'State'}
                        options={LicenseStates}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="address.zipCode"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="ZIP Code" fullWidth />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Deal type" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="closingDate"
                        control={control}
                        render={({ field }) => (
                          <DatePickerInput
                            {...field}
                            control={control}
                            label="Closing Date"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="listingAgent"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Listing Agent"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="salePrice"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Sale Price"
                            fullWidth
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="buyer"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Buyer" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="seller"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Seller" fullWidth />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
              <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                xs={12}
              >
                <Grid item xs></Grid>
                <Grid item>
                  <Button
                    onClick={nextStep}
                    variant={'contained'}
                    disabled={!selectedState}
                  >
                    Next Step »
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Slide>
          <Slide
            in={currentStep === 1}
            direction={previousStep < currentStep ? 'up' : 'down'}
          >
            <Box style={{ display: currentStep === 1 ? 'block' : 'none' }}>
              <Typography mb={2}>
                Please provide information about the Title Company.
              </Typography>
              <Card style={{ padding: '15px', margin: '15px' }}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="titleCompany.name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Title Company Name"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="titleCompany.contact"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Contact" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="titleCompany.phone"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Phone" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="titleCompany.email"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Email" fullWidth />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
              <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                xs={12}
              >
                <Grid item xs>
                  <Button onClick={prevStep} variant={'outlined'}>
                    « Previous Step
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={nextStep} variant={'contained'}>
                    Next Step »
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Slide>
          <Slide
            in={currentStep === 2}
            direction={previousStep < currentStep ? 'up' : 'down'}
          >
            <Box style={{ display: currentStep === 2 ? 'block' : 'none' }}>
              <Typography mb={2}>
                This is who pays the commission, most times that is the Seller.
                Enter only charges that are due to our side of the transaction.
              </Typography>
              {settlementChargesFields.map((field, index) => (
                <Card
                  key={field.id}
                  style={{ padding: '15px', margin: '15px' }}
                >
                  <Grid container>
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs>
                        <Typography variant="h6">Settlement Charge</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => settlementChargesRemove(index)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name={`settlementCharges[${index}].chargeTo` as any}
                          control={control}
                          defaultValue={field.chargeTo}
                          render={({ field }) => (
                            <TextField {...field} label="Charge To" fullWidth />
                          )}
                        />
                      </Grid>

                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={8}>
                          <Controller
                            name={
                              `settlementCharges[${index}].forService` as any
                            }
                            control={control}
                            defaultValue={field.forService}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="For Service"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name={`settlementCharges[${index}].amount` as any}
                            control={control}
                            defaultValue={field.amount}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Amount"
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              ))}
              {settlementChargesFields.length < 3 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    startIcon={<ControlPointOutlined />}
                    onClick={() =>
                      settlementChargesAppend({
                        chargeTo: '',
                        forService: '',
                        amount: '',
                      })
                    }
                    style={{ display: 'flex', margin: 'auto' }}
                  >
                    Add New
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                xs={12}
              >
                <Grid item xs>
                  <Button onClick={prevStep} variant={'outlined'}>
                    « Previous Step
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={nextStep} variant={'contained'}>
                    Next Step »
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Slide>
          <Slide
            in={currentStep === 3}
            direction={previousStep < currentStep ? 'up' : 'down'}
          >
            <Box style={{ display: currentStep === 3 ? 'block' : 'none' }}>
              <Typography mb={2}>
                This is everyone who is getting paid out from the commission
                amount. Do you have a transaction fee owed to Pinnacle (see{' '}
                <Link
                  href={
                    'https://help.hq.pinnaclera.com/en/articles/7051771-filling-out-a-cda'
                  }
                  target={'_blank'}
                >
                  the transaction table
                </Link>
                )? Do you need to pay a team lead? Do you need to pay a referral
                or pay your transaction coordinator? Make sure to list them
                here.
              </Typography>
              <Typography>
                Total payouts must equal total settlement charges.
              </Typography>
              {payoutsFields.map((field, index) => (
                <Card
                  key={field.id}
                  style={{ padding: '15px', margin: '15px' }}
                >
                  <Grid container>
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs>
                        <Typography variant="h6">Payout</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => payoutsRemove(index)}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={9}>
                        <Controller
                          name={`payouts[${index}].payTo` as any}
                          control={control}
                          defaultValue={field.payTo}
                          render={({ field }) => (
                            <TextField {...field} label="Pay To" fullWidth />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Controller
                          name={`payouts[${index}].amount` as any}
                          control={control}
                          defaultValue={field.amount}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Amount"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              ))}

              {payoutsFields.length < 3 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    startIcon={<ControlPointOutlined />}
                    onClick={() => payoutsAppend({ payTo: '', amount: '' })}
                    style={{ display: 'flex', margin: 'auto' }}
                  >
                    Add New
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                xs={12}
              >
                <Grid item xs>
                  <Button onClick={prevStep} variant={'outlined'}>
                    « Previous Step
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={nextStep} variant={'contained'}>
                    Next Step »
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Slide>
          <Slide
            in={currentStep === 4}
            direction={previousStep < currentStep ? 'up' : 'down'}
          >
            <Box style={{ display: currentStep === 4 ? 'block' : 'none' }}>
              <Typography mb={2}>
                Make sure to include the bank payment instructions for everyone
                who is getting paid from this commission. If you are wanting
                payment to be by check you will include that on the ‘Special
                Instructions’ tab.
              </Typography>
              {bankInstructionsFields.map((field, index) => (
                <Card
                  key={field.id}
                  style={{ padding: '15px', margin: '15px' }}
                >
                  <Grid container>
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs>
                        <Typography variant="h6">Instruction</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => bankInstructionsRemove(index)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name={`bankInstructions[${index}].bankName` as any}
                          control={control}
                          defaultValue={field.bankName}
                          render={({ field }) => (
                            <TextField {...field} label="Bank Name" fullWidth />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Controller
                          name={`bankInstructions[${index}].payeeName` as any}
                          control={control}
                          defaultValue={field.payeeName}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Payee Name"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Controller
                          name={
                            `bankInstructions[${index}].routingNumber` as any
                          }
                          control={control}
                          defaultValue={field.routingNumber}
                          render={({ field }) => (
                            <TextField {...field} label="Routing #" fullWidth />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Controller
                          name={
                            `bankInstructions[${index}].accountNumber` as any
                          }
                          control={control}
                          defaultValue={field.accountNumber}
                          render={({ field }) => (
                            <TextField {...field} label="Account #" fullWidth />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              ))}

              {bankInstructionsFields.length < 4 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    startIcon={<ControlPointOutlined />}
                    onClick={() =>
                      bankInstructionsAppend({
                        bankName: '',
                        payeeName: '',
                        routingNumber: '',
                        accountNumber: '',
                      })
                    }
                    style={{ display: 'flex', margin: 'auto' }}
                  >
                    Add New
                  </Button>
                </div>
              ) : (
                <></>
              )}

              <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                xs={12}
              >
                <Grid item xs>
                  <Button onClick={prevStep} variant={'outlined'}>
                    « Previous Step
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={nextStep} variant={'contained'}>
                    Next Step »
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Slide>
          <Slide
            in={currentStep === 5}
            direction={previousStep < currentStep ? 'up' : 'down'}
          >
            <Box style={{ display: currentStep === 5 ? 'block' : 'none' }}>
              <Typography mb={2}>
                Please wire all funds as indicated above. If a physical check is
                required, please make it to “{getStateEntity(selectedState)}”
                and give it to agent at closing or mail to{' '}
                <b>{getStateAddress(selectedState)}</b>
              </Typography>
              {specialInstructionsFields.map((field, index) => (
                <Card
                  key={field.id}
                  style={{ padding: '15px', margin: '15px' }}
                >
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name={
                          `specialInstructions[${index}].instruction` as any
                        }
                        control={control}
                        defaultValue={field.instruction}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Instruction"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={() =>
                                      specialInstructionsRemove(index)
                                    }
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Card>
              ))}

              {specialInstructionsFields.length < 2 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    startIcon={<ControlPointOutlined />}
                    onClick={() =>
                      specialInstructionsAppend({ instruction: '' })
                    }
                    style={{ display: 'flex', margin: 'auto' }}
                  >
                    Add New
                  </Button>
                </div>
              ) : (
                <></>
              )}

              <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                xs={12}
              >
                <Grid item xs>
                  <Button onClick={prevStep} variant={'outlined'}>
                    « Previous Step
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant={'contained'}
                    type="submit"
                    loading={saving}
                  >
                    Next Step »
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Slide>
          <Slide
            in={currentStep === 6}
            direction={previousStep < currentStep ? 'up' : 'down'}
          >
            <Box style={{ display: currentStep === 6 ? 'block' : 'none' }}>
              <Card style={{ padding: '15px', margin: '15px' }}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography mb={4}>
                      Your CDA is generated! Please{' '}
                      <Link href={pdfUrl} target={'_blank'}>
                        click here
                      </Link>{' '}
                      to download and preview it.
                    </Typography>
                    <Typography mb={4}>
                      Click "Previous Step" to make any adjustments.
                    </Typography>
                    <Typography mb={4}>
                      Once you hit "Submit" your CDA will be sent to accounting
                      for review and signature.
                    </Typography>
                    <Typography mb={4}>
                      Accounting will then send the signed and approved CDA to
                      title and upload ir to Skyslope on your behalf.
                    </Typography>
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs>
                        <Button onClick={prevStep} variant={'outlined'}>
                          « Previous Step
                        </Button>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          variant={'contained'}
                          loading={saving}
                          onClick={submitCDA}
                        >
                          Submit CDA
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Slide>
          <Slide
            in={currentStep === 7}
            direction={previousStep < currentStep ? 'up' : 'down'}
          >
            <Box style={{ display: currentStep === 7 ? 'block' : 'none' }}>
              <Card style={{ padding: '15px', margin: '15px' }}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography mb={1}>
                      Your CDA is generated! Please{' '}
                      <Link href={pdfUrl} target={'_blank'}>
                        click here
                      </Link>{' '}
                      to download and preview your entered information
                    </Typography>

                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item xs>
                        <Button onClick={prevStep} variant={'outlined'}>
                          « Previous Step
                        </Button>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Slide>
        </Grid>
      </Grid>
    </form>
  ) : (
    <Typography>
      This CDA is signed already. You can download and preview it{' '}
      <Link href={cda.pdfUrl} target={'_blank'}>
        here
      </Link>
      .
    </Typography>
  );
};

export default CDAEditForm;
