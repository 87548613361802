export const PricingPlans = [
  {
    name: 'Pinnacle Agent Sponsorship Annual Plan',
    planCode: 'pra_annual',
    shortName: 'Annual',
    price: '$495',
    priceDetails: '/year',
    pricingDetails: [
      'Lease fee:',
      'Residential Lease – $50',
      'Commercial Lease – $150',
      '—',
      'Transaction fee:',
      'Up to $200,000 – $150',
      'Up to $250,000 – $175',
      'Up to $300,000 – $200',
      'Up to $350,000 – $225',
      'Up to $400,000 – $250',
      'Up to $450,000 – $275',
      'Up to $500,000 – $300',
      '—',
      '$500,000 + Add $25 per $50,000',
      '—',
      'E&O Insurance Included',
      '*Louisiana agents must carry their own E&O policy per LREC',
    ],
    features: [
      'Keep 100% Of Your Commissions',
      'No monthly fees',
      'Simple brand guidelines',
      'Zero Franchise / Desk Fees',
      'Direct Access To Broker',
      'On-Demand Support: 24-hour broker support, legal support, year end tax documentation, etc.',
      'On-Going Trainings',
      'Simple, Functional Tech Tools',
      'Less than 24 hours to setup',
      'PinnacleHQ: access online tools and marketing services for your business & your clients',
    ],
  },
  {
    name: 'Pinnacle Agent Sponsorship Premier Plan',
    planCode: 'pra_premier',
    shortName: 'Premier',
    price: '$249',
    priceDetails: '/month',
    pricingDetails: [
      'E&O Insurance Included',
      '*Louisiana agents must carry their own E&O policy per LREC',
      '—',
      '$199/month for each additional team member',
    ],
    features: [
      'Keep 100% Of Your Commissions',
      'Subscription-Based Pricing & No Hidden Fees',
      'Simple brand guidelines',
      'Zero Franchise / Desk Fees',
      'Direct Access To Broker',
      'On-Demand Support: 24-hour broker support, legal support, year end tax documentation, etc.',
      'On-Going Trainings',
      'Simple, Functional Tech Tools',
      'Less than 24 hours to setup',
      'PinnacleHQ: access online tools and marketing services for your business & your clients',
    ],
  },
  {
    name: 'Pinnacle Agent Sponsorship Commercial Plan',
    planCode: 'pra_commercial',
    shortName: 'Commercial',
    price: '$249',
    priceDetails: '/month',
    pricingDetails: [
      'Lease fee:',
      'Residential Lease – $50',
      'Commercial Lease – $150',
      '—',
      'Transaction fee:',
      'Up to $200,000 – $150',
      'Up to $250,000 – $175',
      'Up to $300,000 – $200',
      'Up to $350,000 – $225',
      'Up to $400,000 – $250',
      'Up to $450,000 – $275',
      'Up to $500,000 – $300',
      '$500,000 + Add $25 per $50,000',
      '—',
      '$199/month for each additional team member',
      '—',
      'E&O Insurance Included',
      '*Louisiana agents must carry their own E&O policy per LREC',
    ],
    features: [
      'Keep 100% Of Your Commissions',
      'Create Your Own Brand',
      'Subscription-Based Pricing & No Hidden Fees',
      'Simple brand guidelines',
      'Zero Franchise / Desk Fees',
      'On-Going Trainings',
      'Simple, Functional Tech Tools',
      'Less than 24 hours to setup',
      'PinnacleHQ: access online tools and marketing services for your business & your clients',
    ],
  },
  {
    name: 'White Label Sponsorship Pro Plan',
    planCode: 'wl_pro',
    shortName: 'Pro',
    price: '$1250',
    priceDetails: '/month',
    pricingDetails: [
      'E&O Insurance Included',
      '—',
      '$1250/month for each additional team member',
    ],
    features: [
      'Keep 100% Of Your Commissions',
      'Subscription-Based Pricing & No Hidden Fees',
      'Zero Franchise / Desk Fees',
      'Full brand control',
      'Direct Access To Broker',
      'On-Demand Support: 24-hour broker support, legal support, year end tax documentation, etc.',
      'On-Going Trainings',
      'Simple, Functional Tech Tools',
      'PinnacleHQ: access online tools and marketing services for your business & your clients',
      'Less than 24 hours to setup',
    ],
  },
  {
    name: 'White Label Sponsorship Commercial Plan',
    planCode: 'wl_commercial',
    shortName: 'Commercial',
    price: '$1250',
    priceDetails: '/month',
    pricingDetails: [
      'Lease fee:',
      'Residential Lease – $50',
      'Commercial Lease – $150',
      '—',
      'Transaction fee:',
      'Up to $200,000 – $150',
      'Up to $250,000 – $175',
      'Up to $300,000 – $200',
      'Up to $350,000 – $225',
      'Up to $400,000 – $250',
      'Up to $450,000 – $275',
      'Up to $500,000 – $300',
      '$500,000 + Add $25 per $50,000',
      '—',
      'E&O Insurance Included',
      '—',
      '$299/month for each additional team member',
    ],
    features: [
      'Keep 100% Of Your Commissions',
      'Create Your Own Brand',
      'Subscription-Based Pricing & No Hidden Fees',
      'Full brand control',
      'Zero Franchise / Desk Fees',
      'Direct Access To Broker',
      'On-Demand Support: 24-hour broker support, legal support, year end tax documentation, etc.',
      'On-Going Trainings',
      'Simple, Functional Tech Tools',
      'Less than 24 hours to setup',
      'PinnacleHQ: access online tools and marketing services for your business & your clients',
    ],
  },
  {
    name: 'Team Member',
    planCode: 'member',
    shortName: 'Team Member',
    price: '$199',
    priceDetails: '/month',
    pricingDetails: [],
    features: [
      'Work as a Team',
      'Flexible Brand Guidelines',
      'No hidden fees',
      'Zero Franchise / Desk Fees',
      'Direct Access To Broker',
      'On-Demand Support: 24-hour broker support, legal support, year end tax documentation, etc.',
      'On-Going Trainings',
      'Simple, Functional Tech Tools',
      'Less than 24 hours to setup',
      'PinnacleHQ: access online tools and marketing services for your business & your clients',
    ],
  },
];
