import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { MainContent, BigNumber, Logo } from './DealInner.styles';
import { selectIsLoading, selectDeal } from '../../slice/selectors';
import { IDealQueryParams, IDeal } from '../../slice/types';
import Ratings from 'app/components/Ratings';
import { useDealsSlice } from '../../slice';
import { Popover, styled, useTheme } from '@mui/material';
import ScrollToTop from 'app/components/ScrollToTop';
import CopyText from 'app/components/CopyText';
import { usePrepareLocations } from 'app/hooks/usePrepareLocations';
import { QuillEditor } from 'app/containers/Admin/slice/types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { SHARING_DEAL_SUBJECT } from 'utils/constants';
import { useHistory, useLocation } from 'react-router-dom';
import VendorsList from '../../../../components/VendorsList';
import { FacebookIcon } from '../../../../components/CustomSvgs';
import RouteConstants from '../../../../routes';
import { useQuery } from '../../../../hooks/useQuery';
import NoResults from '../../../../components/NoResults';
import { useUserProfileSlice } from '../../../UserSettings/slice';

const CardLink = props => (
  <Link
    underline={'none'}
    target="_blank"
    variant={'body2'}
    sx={{
      'word-break': 'break-all',
      pl: 2,
    }}
    {...props}
  />
);

const CardStack = props => (
  <Stack direction={'row'} alignItems={'center'} width={'100%'} {...props} />
);

const GridContainer = props => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="flex-start"
    rowSpacing={3}
    columnSpacing={{ xs: 2, md: 3, lg: 4 }}
    {...props}
  />
);

//TODO: Find a better way to insert styles

const TextBox = styled(props => <Box {...props} />)(({ theme }) => ({
  ...theme.typography.body1,
  h1: {
    ...theme.typography.h1,
  },
  h2: {
    ...theme.typography.h2,
  },
  h3: {
    ...theme.typography.h3,
  },
  h4: {
    ...theme.typography.h4,
  },
  h5: {
    ...theme.typography.h5,
  },
  h6: {
    ...theme.typography.h6,
  },
  'ul, ol': {
    'list-style-position': 'inside',
    ...theme.typography.body1,
  },
  p: {
    ...theme.typography.body1,
  },
}));

const DealInner = () => {
  const dispatch = useDispatch();
  const { actions } = useDealsSlice();
  const { actions: userActions } = useUserProfileSlice();
  const { dealId } = useParams<IDealQueryParams>();
  const theme = useTheme();
  const deal: IDeal | null = useSelector(selectDeal);
  const isLoading = useSelector(selectIsLoading);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const prepareLocations = usePrepareLocations();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const open = Boolean(anchorEl);

  const handleCopyButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    textToCopy: string,
  ) => {
    setAnchorEl(event.currentTarget);
    navigator.clipboard.writeText(textToCopy);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1000);
  };

  useEffect(() => {
    if (dealId) dispatch(actions.getDeal(Number(dealId)));
  }, [dealId, dispatch, actions]);

  useEffect(() => {
    dispatch(userActions.getUserInfo());
  }, [userActions, dispatch]);

  const prepareSharingText = useCallback(() => {
    if (!deal) return '';
    let text = `${deal.vendor.companyName}. ${deal.vendor.shortDescription} `;
    if (
      deal.sharingLink ||
      deal.vendor.phoneNumber ||
      deal.vendor.email ||
      deal.vendor.facebook
    )
      text += 'CONTACTS: ';
    if (deal.sharingLink) text += `Link: ${deal.sharingLink} ; `;
    if (deal.vendor.phoneNumber) text += `Phone: ${deal.vendor.phoneNumber} ; `;
    if (deal.vendor.email) text += `Email: ${deal.vendor.email} ; `;
    if (deal.vendor.facebook) text += `Facebook: ${deal.vendor.facebook} ; `;

    return text;
  }, [deal]);

  const SpecialOfferCard = useCallback(
    display => {
      if (deal) {
        return (
          <Card
            sx={{
              bgcolor: '#E3F2FD',
              flexBasis: '50%',
              display: { ...display },
            }}
          >
            <CardContent>
              <Typography variant="h5" paddingY={2} align={'center'}>
                Special Offer
              </Typography>
              <Typography align={'center'}>
                {deal.vendor.clientValue}
              </Typography>
            </CardContent>
            <CardActions sx={{ p: 2 }}>
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                width={'100%'}
                pb={1}
              >
                {deal.sharingLink && (
                  <CopyText copyText={deal.sharingLink} ctaText="Copy link" />
                )}
              </Stack>
            </CardActions>
          </Card>
        );
      }
    },
    [deal],
  );

  const ratings = useMemo(() => {
    const starClickHandler = (starIndex: number) => {
      if (deal?.vendor.id) {
        dispatch(
          actions.setRating({
            dealId: deal?.vendor.id,
            data: { stars: starIndex },
          }),
        );
      }
    };

    if (deal && deal.ratings)
      return (
        <Ratings
          count={deal?.ratings.count}
          average={deal?.ratings.average}
          userRating={deal?.ratings.my_rating || 0}
          onStarClick={starClickHandler}
        />
      );
    return null;
  }, [deal, actions, dispatch]);

  if (isLoading)
    return (
      <Container maxWidth="xl">
        <ScrollToTop />
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'flex-end'}
          height={'50vh'}
        >
          <CircularProgress size={50} />
        </Box>
      </Container>
    );

  if (deal) {
    const description =
      deal.vendor.description &&
      deal.vendor.description !== QuillEditor.EmptyHtml
        ? deal.vendor.description
        : deal.vendor.shortDescription;

    return (
      <Container maxWidth="lg">
        <Box sx={{ py: 3 }}>
          <Button
            size="medium"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              location.key
                ? history.goBack()
                : history.push(`${RouteConstants.deals.all}?${query}`)
            }
            sx={{ pl: 0 }}
          >
            Back to vetted vendors
          </Button>
        </Box>
        <GridContainer>
          <Grid item xs={12} md={7}>
            <Stack spacing={3}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                {deal.vendor.logoUrl && (
                  <Logo src={deal.vendor.logoUrl} alt="Company logo" />
                )}
                {ratings}
              </Stack>
              <Typography variant={'h4'}>{deal.vendor.title}</Typography>
              <Box>
                {prepareLocations(
                  Boolean(deal.vendor.countryWide),
                  deal.locationsFullNames,
                )}
              </Box>
              {description && (
                <Box>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </Box>
              )}
            </Stack>
          </Grid>
          <Grid item sx={{ width: { md: '360px' } }}>
            <Stack
              spacing={4}
              direction={{ xs: 'column', sm: 'row', md: 'column' }}
            >
              <Card sx={{ flexBasis: '50%' }}>
                <CardContent>
                  <Typography variant="h5" paddingY={2}>
                    Contacts
                  </Typography>
                  <Stack>
                    {deal.sharingLink && (
                      <CardStack pb={1}>
                        <InsertLinkOutlinedIcon fontSize={'small'} />
                        <CardLink
                          href={deal.sharingLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {deal.vendor.companyName}
                        </CardLink>
                      </CardStack>
                    )}
                    {deal.vendor.phoneNumber && (
                      <CardStack pb={1}>
                        <PhoneInTalkOutlinedIcon fontSize={'small'} />
                        <CardLink
                          href={`tel:${deal.vendor.phoneNumber}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {deal.vendor.phoneNumber}
                        </CardLink>
                      </CardStack>
                    )}
                    {deal.vendor.email && (
                      <CardStack>
                        <EmailOutlinedIcon fontSize={'small'} />
                        <CardLink
                          href={`mailto:${deal.vendor.email}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {deal.vendor.email}
                        </CardLink>
                      </CardStack>
                    )}
                    {deal.vendor.facebook && (
                      <CardStack>
                        <FacebookIcon
                          fontSize={'small'}
                          viewBox={'-1.5 -2 20 20'}
                        />
                        <CardLink
                          href={deal.vendor.facebook}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View on Facebook
                        </CardLink>
                      </CardStack>
                    )}
                  </Stack>
                </CardContent>
                <Divider />
                <CardActions sx={{ p: 2 }}>
                  <Stack pt={2}>
                    <Typography color={theme.palette.common.black}>
                      Share contacts with your client:
                    </Typography>
                    <Box>
                      {/*TODO: Refactor*/}
                      <Button
                        startIcon={<EmailOutlinedIcon />}
                        sx={{ mr: 2 }}
                        component={'a'}
                        target="_blank"
                        rel="noreferrer"
                        href={`mailto:?subject=${SHARING_DEAL_SUBJECT}&body=${encodeURIComponent(
                          prepareSharingText(),
                        )}`}
                      >
                        Email
                      </Button>
                      {deal.sharingLink && (
                        <Button
                          startIcon={<ContentCopyOutlinedIcon />}
                          onClick={event =>
                            handleCopyButtonClick(event, deal.sharingLink!)
                          }
                        >
                          Copy
                        </Button>
                      )}
                    </Box>
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Typography sx={{ p: 2 }}>Copied!</Typography>
                    </Popover>
                  </Stack>
                </CardActions>
              </Card>
              {SpecialOfferCard({ xs: 'block', md: 'none' })}
            </Stack>
          </Grid>
        </GridContainer>
        <GridContainer pt={3}>
          <Grid item xs={12} md={7}>
            <Stack spacing={3} pb={3}>
              {deal.vendor.partnerDescription &&
                deal.vendor.partnerDescription !== QuillEditor.EmptyHtml && (
                  <TextBox
                    dangerouslySetInnerHTML={{
                      __html: deal.vendor.partnerDescription,
                    }}
                  />
                )}
              {Boolean(deal.clicks) && (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  gap={2.4}
                  width={'100%'}
                  borderRadius={2}
                  p={2}
                  bgcolor={theme.palette.common.white}
                  // TODO: use custom shadow
                  sx={{ boxShadow: 2 }}
                >
                  <BigNumber>{deal.clicks}</BigNumber>
                  <Typography>Clients are interested in this offer</Typography>
                </Stack>
              )}
              {deal.relatedDeals && deal.relatedDeals.length > 0 && (
                <Typography variant={'h5'}>
                  Your clients may also like
                </Typography>
              )}
              {deal.relatedDeals && deal.relatedDeals.length > 0 && (
                <VendorsList deals={deal.relatedDeals} isLoading={isLoading} />
              )}
            </Stack>
          </Grid>
          <Grid
            item
            sx={{
              width: { md: '360px' },
            }}
          >
            {SpecialOfferCard({ xs: 'none', md: 'block' })}
          </Grid>
        </GridContainer>
      </Container>
    );
  }

  return (
    <MainContent>
      <ScrollToTop />
      <NoResults title={'No vendor found'} />
    </MainContent>
  );
};

export default DealInner;
