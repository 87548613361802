import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import api from '../api';
import { AxiosError } from 'axios';
import { ICDA } from './type';
import * as Types from '../admin/types';
import { ITeam } from '../admin/types';

interface GetMyCDAs<T> {
  options?: UseQueryOptions<ICDA[], AxiosError, T, any>;
}

interface GetCDA<T> {
  options?: UseQueryOptions<ICDA, AxiosError, T, any>;
}

interface PostUpdateCda<T> {
  options?: UseMutationOptions<ICDA, AxiosError, ICDA>;
}

interface SubmitCda<T> {
  options?: UseMutationOptions<any, AxiosError, any>;
}

export function useGetMyCdas<T = ICDA[]>(props?: GetMyCDAs<T>) {
  return useQuery(
    ['myCdas'],
    async () => {
      const result = await api.get(`/cda/list`);
      return result.data;
    },
    props?.options,
  );
}

export function useGetCda<T = ICDA>(cdaId?: string, props?: GetCDA<T>) {
  return useQuery(
    ['cda', cdaId],
    async () => {
      if (cdaId == null) {
        return null;
      }
      const result = await api.get(`/cda/${cdaId}`);
      return result.data;
    },
    props?.options,
  );
}

export function useUpdateCda<T = ICDA>(props?: PostUpdateCda<T>) {
  return useMutation(
    ['updateCda'],
    async (data: ICDA) => {
      const result = await api.post(`cda/update/${data.id}`, data);
      return result.data;
    },
    props?.options,
  );
}

export function useCreateCda<T = ICDA>(props?: PostUpdateCda<T>) {
  return useMutation(
    ['createCda'],
    async (data: ICDA) => {
      const result = await api.put<ICDA>(`cda/create`, data);
      return result.data;
    },
    props?.options,
  );
}

export function useSubmitCda<T = ICDA>(cdaId?: number, props?: SubmitCda<T>) {
  return useMutation(
    ['submitCda'],
    async (cdaId?: number) => {
      if (cdaId) {
        const result = await api.post(`/cda/submit/${cdaId}`);
        return result.data;
      }
    },
    props?.options,
  );
}
