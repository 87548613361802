import React, { useEffect, useState } from 'react';
import { useGetCda } from '../../queries/CDA/user';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import CDAEditForm from '../../components/CDA/editForm';

interface ICdaCreateEditProps {
  isEdit?: boolean;
  filter?: object;
}

const CdaCreateEdit: React.FC<ICdaCreateEditProps> = ({ isEdit }) => {
  const { cdaId } = useParams<{ cdaId: string }>();
  const { data: cda, error } = useGetCda(cdaId, {
    options: {
      refetchOnWindowFocus: false,
    },
  });
  const [updateCounter, setUpdateCounter] = useState(0);

  const handleMutationSuccess = () => {
    setUpdateCounter(prev => prev + 1);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return !isEdit || cda ? (
    <Grid
      container
      style={{ display: 'grid', placeItems: 'center', height: '100vh' }}
    >
      <CDAEditForm
        cda={cda}
        onMutationSuccess={handleMutationSuccess}
        isAdmin={false}
      />
    </Grid>
  ) : (
    <div>Loading...</div>
  );
};

export default CdaCreateEdit;
