import React, { ReactNode, useMemo, useState } from 'react';
import {
  Avatar,
  Stack,
  Typography,
  Toolbar,
  List,
  IconButton,
  Drawer,
  Divider,
  Box,
  AppBar,
  Collapse,
} from '@mui/material';
import RouteConstants from '../../routes';
import LogoImg from '../../../assets/images/pinnacleHqLogo.svg';
import SidebarItem from './SidebarItem';
import MenuIcon from '@mui/icons-material/Menu';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { generatePath, Link, useLocation } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import triangleBackground from '../../../assets/images/triangle_background.png';
import brandonKoletsky from '../../../assets/images/Avatar_Brandon_Koletsky.png';
import {
  useRedirectTo,
  useResetUserData,
  useStore,
} from '../../hooks/useStore';
import { useLogout } from '../../queries/auth/useAuthQueries';

interface ISidebar {
  children: ReactNode;
}

export interface ISideBarItems {
  path?: string;
  title?: string;
  exact?: boolean;
  icon?: ReactNode;
  secondary?: boolean;
  subitems?: ISideBarItems[];
}

const Logo = styled(props => (
  <Box component={'img'} {...props} alt="Pinnacle Realthy Advisors logo" />
))({
  objectFit: 'contain',
  objectPosition: 'left center',
});

export const LogoLink = styled(Link)`
  padding: 20px 0;
`;

const isOlderThan30Days = dateObj => {
  if (!(dateObj instanceof Date)) {
    dateObj = new Date(dateObj);
  }

  const thirtyDaysAgo = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;

  return dateObj.getTime() < thirtyDaysAgo;
};

const SideBar = ({ children }: ISidebar) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pathname } = useLocation();
  const pathParts = pathname.split('/');
  const drawerWidth = 260;
  const isDesktop = useResponsive('up', 'lg');
  const user = useStore(s => s.user);
  const theme = useTheme();
  const resetUserData = useResetUserData();
  const setRedirectTo = useRedirectTo();

  const getPathForUserPlan = (userPlan?: string) => {
    if (userPlan?.includes('commercial')) {
      return RouteConstants.boosts.commercial;
    }

    return RouteConstants.boosts.residential;
  };

  const navItems = useMemo<ISideBarItems[]>(() => {
    return [
      {
        path: RouteConstants.profile.index,
        title: 'My Profile',
        icon: <AccountCircleOutlinedIcon />,
      },
      {
        path: RouteConstants.myResources.index,
        title: 'My Resources/Tools',
        icon: <BackupTableOutlinedIcon />,
      },
      user?.createdAt && isOlderThan30Days(user?.createdAt)
        ? {
            path: RouteConstants.getPaid.index,
            title: 'How to get Paid',
            icon: <MonetizationOnOutlinedIcon />,
          }
        : {
            path: RouteConstants.onboarding.index,
            title: 'My Onboarding Resources',
            icon: <AddTaskOutlinedIcon />,
          },
      {
        path: RouteConstants.wiki.index,
        title: 'My Useful links',
        icon: <BookmarksOutlinedIcon />,
      },
      {
        path: RouteConstants.boosts.all,
        title: 'Add-On Services',
        icon: <AppsOutlinedIcon />,
      },
      {
        path: generatePath(RouteConstants.boosts.boostInner, {
          boostId: 155,
        }),
        title: 'Agent Mentorship',
        icon: <SchoolOutlinedIcon />,
        exact: true,
      },
      {
        path: generatePath(RouteConstants.cda.list),
        title: 'CDA',
        icon: <DocumentScannerOutlinedIcon />,
        exact: true,
      },
    ];
  }, [user]);

  const { mutate: logout } = useLogout({
    options: {
      onSuccess: () => {
        resetUserData();
        setRedirectTo(RouteConstants.auth.login);
      },
    },
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isDropdownOpen = (items?: ISideBarItems[]): boolean => {
    return Boolean(items?.some(item => match(item.path || '', item.exact)));
  };

  // Match path if first string after dash is the same as current pathname
  const match = (path: string, exact?: boolean) => {
    if (!path) {
      return false;
    }

    if (exact) {
      return path.toString() === pathParts.join('/');
    }

    return path.includes(pathParts[1]);
  };

  return (
    <>
      {user ? (
        <Box>
          <Box
            component="nav"
            aria-label="mailbox folders"
            sx={{
              flexShrink: 0,
              width: { xs: 0, lg: drawerWidth },
            }}
          >
            <AppBar
              position="fixed"
              sx={{
                display: isDesktop ? 'none' : 'block',
              }}
            >
              <Toolbar
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <LogoLink to={RouteConstants.myResources.index}>
                  <Logo
                    src={LogoImg}
                    alt="Pinnacle HQ Logo"
                    width={180}
                    height={40}
                  />
                </LogoLink>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    display: isDesktop ? 'none' : 'block',
                    '&:hover': { backgroundColor: 'unset' },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Drawer
              open={isDesktop ? true : mobileOpen}
              variant={isDesktop ? 'permanent' : 'temporary'}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: !isDesktop && true,
              }}
              PaperProps={{
                sx: {
                  width: drawerWidth,
                  bgcolor: 'primary.main',
                },
              }}
            >
              <Toolbar>
                <LogoLink to={RouteConstants.myResources.index}>
                  <Logo src={LogoImg} alt="Realthy logo" />
                </LogoLink>
              </Toolbar>
              <Divider />
              <List disablePadding>
                {navItems.map(item => (
                  <React.Fragment key={item.title}>
                    <>
                      <SidebarItem
                        key={item.title}
                        item={item}
                        active={match}
                        onClick={handleDrawerToggle}
                      />
                      <Collapse in={isDropdownOpen(item.subitems)}>
                        {item.subitems && (
                          <>
                            {item.subitems.map(subItem => (
                              <SidebarItem
                                key={subItem.title}
                                item={subItem}
                                active={match}
                                onClick={handleDrawerToggle}
                              />
                            ))}
                          </>
                        )}
                      </Collapse>
                    </>
                  </React.Fragment>
                ))}
              </List>

              <Box sx={{ flexGrow: 1 }} />
              <Box>
                {user?.admin && (
                  <SidebarItem
                    item={{
                      path: RouteConstants.admin.index,
                      title: 'Admin',
                      icon: <HandymanOutlinedIcon />,
                    }}
                    active={match}
                    onClick={handleDrawerToggle}
                  />
                )}
                <SidebarItem
                  item={{
                    title: 'Logout',
                    icon: <LogoutOutlinedIcon />,
                  }}
                  onClick={() => logout()}
                />
              </Box>
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              mt: isDesktop ? 0 : '80px',
              ml: { xs: 0, lg: `${drawerWidth}px` },
            }}
          >
            {children}
          </Box>
        </Box>
      ) : (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          height={'100%'}
          position={'relative'}
        >
          <Box
            sx={{
              maxWidth: { xs: 'unset', md: 400, lg: 500 },
              backgroundImage: `url(${triangleBackground})`,
              backgroundSize: 'cover',
              px: 4,
            }}
          >
            <Stack
              justifyContent={'center'}
              height={'100%'}
              spacing={3}
              py={{ xs: 2, sm: 4 }}
            >
              <Logo
                src={LogoImg}
                maxWidth={{ xs: 215, sm: 300 }}
                maxHeight={{ xs: 30, sm: 70 }}
              />
              <Stack>
                <Typography
                  variant={'body1'}
                  color={'white'}
                  fontStyle={'italic'}
                >
                  “I don’t think you’ll find a better place that has that type
                  of support especially when it comes to your needs as an
                  agent.”
                </Typography>
                <Stack
                  direction={'row'}
                  spacing={2}
                  alignItems={'center'}
                  pt={2}
                  alignSelf={{ xs: 'flex-end', md: 'flex-start' }}
                >
                  <Avatar
                    alt="Brandon Koletsky"
                    src={brandonKoletsky}
                    sx={{
                      width: { xs: '56px', md: ' 77px' },
                      height: { xs: '56px', md: ' 77px' },
                    }}
                  />
                  <Typography color={'white'} variant={'body1'}>
                    Brandon Koletksy
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Stack
            component="main"
            alignItems={'center'}
            width={'100%'}
            flexGrow={1}
            height={'100%'}
            position={'relative'}
          >
            {children}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default SideBar;
