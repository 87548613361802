import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import api from '../api';
import { AxiosError } from 'axios';
import { ICDA } from './type';
import * as Types from '../admin/types';
import { ITeam } from '../admin/types';

interface GetCDAs<T> {
  options?: UseQueryOptions<ICDA[], AxiosError, T, any>;
}

interface PostSignCda<T> {
  options?: UseMutationOptions<T, AxiosError, RequestCdaChangesPayload>;
}

interface UpdateCda<T> {
  options?: UseMutationOptions<ICDA, AxiosError, ICDA>;
}

interface RequestCdaChangesPayload {
  cdaId: string;
  changes?: string;
  ccs?: Array<string>;
}

interface GetCDA<T> {
  options?: UseQueryOptions<ICDA, AxiosError, T, any>;
}

export function useGetCdas<T = ICDA[]>(props?: GetCDAs<T>) {
  return useQuery(
    ['adminCdas'],
    async () => {
      const result = await api.get(`/admin/cda/list`);
      return result.data;
    },
    props?.options,
  );
}

export function useGetCdaAdmin<T = ICDA>(cdaId?: string, props?: GetCDA<T>) {
  return useQuery(
    ['cda', cdaId],
    async () => {
      if (cdaId == null) {
        return null;
      }
      const result = await api.get(`/admin/cda/${cdaId}`);
      return result.data;
    },
    props?.options,
  );
}

export function useUpdateCdaAdmin<T = ICDA>(props?: UpdateCda<T>) {
  return useMutation(
    ['updateCda'],
    async (data: ICDA) => {
      const result = await api.post(`admin/cda/update/${data.id}`, data);
      return result.data;
    },
    props?.options,
  );
}

export function useSignCda<T = ICDA>(
  cdaId?: string,
  ccs?: Array<string>,
  props?: PostSignCda<T>,
) {
  return useMutation(
    async ({ cdaId, ccs }: { cdaId: string; ccs: Array<string> }) => {
      console.log(ccs);
      const result = await api.post(`/admin/cda/sign/${cdaId}`, ccs);
      return result.data;
    },
    props?.options,
  );
}

export function useUnsignCda<T = ICDA>(cdaId?: string, props?: PostSignCda<T>) {
  return useMutation(async ({ cdaId: string }) => {
    const result = await api.post(`/admin/cda/unsign/${cdaId}`);
    return result.data;
  }, props?.options);
}

export function useRequestCdaChanges<T = ICDA>(props?: PostSignCda<T>) {
  return useMutation(async ({ cdaId, changes }) => {
    const result = await api.post(`/admin/cda/requestChanges/${cdaId}`, {
      changes: changes,
    });
    return result.data;
  }, props?.options);
}
