import React from 'react';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';
import { Box, Container, Grid, Typography } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import OffersList from '../../../components/OffersList';
import { useFetchOnlineTools } from '../../../queries/onlineTools/useOnlineToolsQueries';
import { GeneralToolsProps } from '../GeneralTools';
import Routes from '../../../routes';
import ContactTile from '../../../components/ContactTile';
import { useStore } from '../../../hooks/useStore';

const contacts = [
  {
    photo:
      'https://pinnacle-hq-uploads-prod.s3.amazonaws.com/proxy-64b93fb9c544f.png',
    name: 'Bre Enright',
    position: 'Agent Success Manager',
    phones: ['972-338-5441 ext. 3'],
    emails: ['Success@pinnaclera.com'],
    description:
      'Our Success Team is dedicated to ensuring our agents have all the information and resources needed to effectively utilize Pinnacle’s platform. Please contact "Success" for help.',
    states: ['ALL'],
    footer: (
      <Typography>
        <a className="launch_intercom" href="#">
          Chat with Bre
        </a>
      </Typography>
    ),
  },
  {
    photo:
      'https://pinnaclera.com/wp-content/uploads/2022/11/Matt_Henning-e1669380654731.jpeg',
    name: 'Matt Henning',
    position: 'VP, Operations & Accounting',
    phones: ['972-338-5441 ext. 2'],
    emails: ['Operations@pinnaclera.com', 'Accounting@pinnaclera.com'],
    description:
      'Please contact "Operations" for questions on state/local licensing or questions regarding your membership with us. <br/><br/>Please contact "Accounting" for questions relating to CDAs (commission disbursements), payments or closings.',
    states: ['ALL'],
    footer: (
      <Typography>
        <a href="mailto:Operations@pinnaclera.com">Email Matt</a>
      </Typography>
    ),
  },
  {
    photo:
      'https://pinnaclera.com/wp-content/uploads/2022/11/DALL%C2%B7E-2023-04-05-10.36.53-expand-image.jpg',
    name: 'Brent Porter',
    position: 'Texas Broker of Record',
    phones: ['214-884-2894'],
    emails: ['Texasbroker@pinnaclera.com'],
    description:
      'Need to know what to put on a contract? Need to discuss negotiation strategy? Questions concerning compliance or best practices? Please contact your broker for help. ',
    states: ['TX'],
    footer: (
      <Typography>
        <a href="mailto:Texasbroker@pinnaclera.com">Email Brent</a>
      </Typography>
    ),
  },
  {
    photo:
      'https://pinnaclera.com/wp-content/uploads/2022/11/DALL%C2%B7E-2023-04-05-10.36.53-expand-image.jpg',
    name: 'Brent Porter',
    position: 'Florida Broker of Record',
    phones: ['214-884-2894'],
    emails: ['Floridabroker@pinnaclera.com'],
    description:
      'Need to know what to put on a contract? Need to discuss negotiation strategy? Questions concerning compliance or best practices? Please contact your broker for help.',
    states: ['FL'],
    footer: (
      <Typography>
        <a href="mailto:Floridabroker@pinnaclera.com">Email Brent</a>
      </Typography>
    ),
  },
  {
    photo:
      'https://pinnaclera.com/wp-content/uploads/2022/11/Randolph_Smith-e1669380348476.jpeg',
    name: 'Randolph Smith',
    position: 'Louisiana Broker of Record',
    phones: ['318-233-1045'],
    emails: ['Louisianabroker@pinnaclera.com'],
    description:
      'Need to know what to put on a contract? Need to discuss negotiation strategy? Questions concerning compliance or best practices? Please contact your broker for help.',
    states: ['LA', 'NC', 'AR'],
    footer: (
      <Typography>
        <a href="mailto:Louisianabroker@pinnaclera.com">Email Randolph</a>
      </Typography>
    ),
  },
];

const MyResources: React.FC<GeneralToolsProps> = ({ filter }) => {
  const { data, isLoading } = useFetchOnlineTools(filter);
  const tools = data ? data.online_tools : null;
  const user = useStore(s => s.user);

  return (
    <Container maxWidth="xl">
      <ScrollToTopOnMount />
      <PageHeader title="My Support Team" />
      <Grid container spacing={2} justifyContent="center">
        {contacts.map((contact, index) => {
          if (
            user &&
            (contact.states.includes(user.licenseState) ||
              contact.states.includes('ALL'))
          ) {
            return (
              <Grid item xs={12} sm={6} md={4} sx={{ mb: 1 }} key={index}>
                <ContactTile contact={contact} />
              </Grid>
            );
          }
        })}
      </Grid>
      <PageHeader title="My Resources"></PageHeader>

      <OffersList
        isLoading={isLoading}
        boosts={tools}
        routeName={Routes.myResources.inner}
      />
    </Container>
  );
};

export default MyResources;
