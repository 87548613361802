import React from 'react';
import {
  Container,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Link,
  StepContent,
  styled,
  stepConnectorClasses,
  StepConnector,
  Stack,
} from '@mui/material';
import PageHeader from '../../components/PageHeader';
import { useStore } from '../../hooks/useStore';
import ContactTile from '../../components/ContactTile';

const OutlinedStepper = styled(props => <Step {...props} />)(({ theme }) => ({
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-root': {
    height: '40px',
    width: '40px',
  },
  '& .MuiStepLabel-label .Mui-active .MuiStepLabel-alternativeLabel': {
    color: theme.palette.primary.main, // Just text label (ACTIVE)
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: theme.palette.primary.main, // circle's number (ACTIVE)
  },
  '& .MuiStepper-root.MuiStepConnector-vertical': {
    marginLeft: '20px',
  },
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    marginLeft: '8px',
    borderColor: theme.palette.primary.main,
  },
}));

const OutlinedStepContent = styled(props => <StepContent {...props} />)(
  ({ theme }) => ({
    marginLeft: '20px',
    borderColor: theme.palette.primary.main,
  }),
);

const contacts = [
  {
    photo:
      'https://pinnaclera.com/wp-content/uploads/2022/11/Ally_-e1669380753638.jpeg',
    name: 'Ally Broadnax',
    position: 'Onboarding Specialist',
    phones: ['972-338-5441 ext. 3'],
    emails: ['Onboarding@pinnaclera.com'],
    description:
      'Our Onboarding Team is here to help facilitate your transition over to Pinnacle. Please contact “Onboarding” with any questions as you get started with us.',
    footer: (
      <Typography>
        <a className="launch_intercom" href="#">
          Chat with Ally
        </a>
      </Typography>
    ),
  },
  {
    photo:
      'https://pinnacle-hq-uploads-prod.s3.amazonaws.com/proxy-64b93fb9c544f.png',
    name: 'Bre Enright',
    position: 'Agent Success Manager',
    phones: ['972-338-5441 ext. 3'],
    emails: ['Success@pinnaclera.com'],
    description:
      'Our Success Team is dedicated to ensuring our agents have all the information and resources needed to effectively utilize Pinnacle’s platform. Please contact "Success" for help.',
    footer: (
      <Typography>
        <a className="launch_intercom" href="#">
          Chat with Bre
        </a>
      </Typography>
    ),
  },
];

const getCdaLink = state => {
  const links = {
    AR: 'https://docs.google.com/spreadsheets/d/1VmyKIVjSJPF1zxFY-dWTDGUuAJxptOhH/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
    TX: 'https://docs.google.com/spreadsheets/d/1miihYgu76W6t45d1VXs6EMnfmTRBEGn2/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
    FL: 'https://docs.google.com/spreadsheets/d/1XPu-856yOOza_J0X31upB6goRmJKkWc7/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
    LA: 'https://docs.google.com/spreadsheets/d/1NZ-bTNUGckB0ahi2Opj-xoXFsG1DjdiT/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
    NC: 'https://docs.google.com/spreadsheets/d/1h5IcOhA_e8tFM61yzAQszeshQaQ4JUx8/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
    CA: 'https://docs.google.com/spreadsheets/d/1ORpgKKKOTKGFhCthfSEAwf6ljXW-Qkeu/edit#gid=926692330',
  };

  return links[state];
};

const getLeaseInvoiceLink = state => {
  const links = {
    AR: 'https://docs.google.com/spreadsheets/d/1wU8GGrCoNdNePJFhzR-rUqhsWnIsR3rk/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
    TX: 'https://docs.google.com/spreadsheets/d/1ZjhZ3toc874WIRmUAQyOP-QoT4KCy3c5/edit#gid=2088462812',
    FL: 'https://docs.google.com/spreadsheets/d/1nW_VkTJJ7auyuRAxVtNIDmoffJ4U--Di/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
    LA: 'https://drive.google.com/file/d/1LrKDqsIdIB3lIIyEnpZiZh2Z5Y_x5P4y/view',
    NC: 'https://docs.google.com/spreadsheets/d/14ouWU0cvPlpvzaQeGDzaECM3GP6idhnU/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
    CA: 'https://docs.google.com/spreadsheets/d/1Ob3xqkZH9E4Cg8hvf9aR5yhUqsJbZGCw/edit?usp=sharing&ouid=108783469103639890982&rtpof=true&sd=true',
  };

  return links[state];
};

const GettingPaid = () => {
  const user = useStore(s => s.user);

  return (
    <Container maxWidth="xl">
      <PageHeader title="My Support Team" />
      <Grid container spacing={2} justifyContent="center">
        {contacts.map((contact, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} sx={{ mb: 1 }} key={index}>
              <ContactTile contact={contact} />
            </Grid>
          );
        })}
      </Grid>
      <PageHeader withDivider title="How To Get Paid" />

      <Grid container pb={8}>
        <Grid item xs={12} md={8}>
          <Stepper orientation="vertical" connector={<CustomConnector />}>
            <OutlinedStepper active>
              <StepLabel>
                <Typography variant={'h6'}>
                  Create a transaction in{' '}
                  <Link
                    href={'https://app.skyslope.com/AgentHome.aspx'}
                    target={'_blank'}
                  >
                    Skyslope
                  </Link>
                  , our transaction management platform.
                </Typography>
              </StepLabel>
              <OutlinedStepContent>
                <Stack spacing={2}>
                  <Typography>
                    Best practice is to get signed docs into Skyslope for
                    compliance to review{' '}
                    <u>
                      <b>within 3 days</b>
                    </u>
                    .
                  </Typography>
                  <Typography>
                    Skyslope How-tos:{' '}
                    <Link
                      href={'https://youtu.be/S5ftJAYqaac?feature=shared'}
                      target={'_blank'}
                    >
                      Create a listing transaction
                    </Link>
                    ,{' '}
                    <Link
                      href={'https://youtu.be/oOVw2S4fUo8/AgentHome.aspx'}
                      target={'_blank'}
                    >
                      Create a purchase transaction
                    </Link>
                  </Typography>
                </Stack>
              </OutlinedStepContent>
            </OutlinedStepper>
            <OutlinedStepper active>
              <StepLabel>
                <Typography variant={'h6'}>
                  Once your files have been reviewed by compliance and at least
                  5 days prior to close, fill out the Commission Disbursement
                  Authorization form (
                  <Link href={getCdaLink(user?.licenseState)} target="_blank">
                    CDA
                  </Link>
                  ) or{' '}
                  <Link
                    href={getLeaseInvoiceLink(user?.licenseState)}
                    target="_blank"
                  >
                    Lease Invoice
                  </Link>
                  .
                </Typography>
              </StepLabel>
              <OutlinedStepContent>
                <Stack spacing={2}>
                  <Typography>
                    Short Video Tutorial:{' '}
                    <Link
                      href={'https://www.youtube.com/watch?v=1MoSUez-7i0'}
                      target={'_blank'}
                    >
                      How to Fill out a CDA
                    </Link>
                  </Typography>
                  <Typography>
                    Webinar:{' '}
                    <Link
                      href={'https://youtu.be/S5ftJAYqaac?feature=shared'}
                      target={'_blank'}
                    >
                      “I have a Transaction, Now What!?”
                    </Link>{' '}
                    walks through how to create a skyslope listing and
                    transaction file and different scenarios for filling out the
                    CDA and lease invoice.
                  </Typography>
                </Stack>
              </OutlinedStepContent>
            </OutlinedStepper>
            <OutlinedStepper active>
              <StepLabel>
                <Typography variant={'h6'}>
                  Once you have filled out your CDA or Lease Invoice - Email a
                  copy of your excel or Google sheet file to{' '}
                  <Link href={'mailto:accounting@pinnaclera.com'}>
                    accounting@pinnaclera.com
                  </Link>
                  .
                </Typography>
              </StepLabel>
              <OutlinedStepContent>
                <Stack spacing={2}>
                  <Typography>
                    Accounting will review your Skyslope transaction to make
                    sure everything is complete. Once approved, Accounting will
                    send the signed, final CDA or lease invoice to your billing
                    contact for you and CC you on the email. Accounting will
                    also upload it to the Skyslope checklist on your behalf.
                  </Typography>
                </Stack>
              </OutlinedStepContent>
            </OutlinedStepper>
          </Stepper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GettingPaid;
