import React from 'react';
import Chip from '@mui/material/Chip';

const StatusChip = ({ status }) => {
  switch (status) {
    case 'draft':
      return <Chip label={'Draft'} color={'warning'} />;
    case 'submitted':
      return <Chip label={'Submitted'} color={'info'} />;
    case 'signed':
      return <Chip label={'Signed'} color={'success'} />;
    case 'changes_requested':
      return <Chip label={'Changes Requested'} color={'secondary'} />;
    default:
      return <Chip label={status} color={'default'} />;
  }
};

export default StatusChip;
