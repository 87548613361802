/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'styles/App.less';
import '../assets/fonts.css';
import { GlobalStyle } from 'styles/global-styles';

import GlobalWrapper from './containers/Global/parts/GlobalWrapper/GlobalWrapper';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MainRouter } from './mainRouter';
import { IntercomProvider } from 'react-use-intercom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  MutationCache,
} from '@tanstack/react-query';
import { useSnackBar } from './components/CustomSnackbar';

const INTERCOM_APP_ID = 'grzacjug';

const queryKeysToIgnore = ['user', 'signUp'];

export function App() {
  const snackbar = useSnackBar();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Don't retry for 404 not found
        retry: (failureCount, error: any) => {
          return error?.response?.status === 404 ? false : failureCount < 3;
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error: any, query) => {
        if (
          !queryKeysToIgnore.includes(query.queryKey[0] as string) &&
          error?.response?.status !== 404
        ) {
          snackbar.showSnackBar(
            'Something went wrong while fetching data. Please try again later.',
            'error',
          );
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (error: any) => {
        // Don't show not authorized error
        // Don't show error for 404 not found
        if (error?.response?.status !== 401) {
          snackbar.showSnackBar(error?.message, 'error');
        }
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Helmet
          titleTemplate="%s | PinnacleHQ"
          defaultTitle="PinnacleHQ"
          htmlAttributes={{ lang: 'en' }}
        >
          <meta name="description" content="PinnacleHQ application" />
        </Helmet>
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot={true}
          autoBootProps={{ customLauncherSelector: '.launch_intercom' }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <QueryClientProvider client={queryClient}>
              <GlobalWrapper>
                <MainRouter />
              </GlobalWrapper>
            </QueryClientProvider>
          </LocalizationProvider>
        </IntercomProvider>

        <GlobalStyle />
      </BrowserRouter>
    </QueryClientProvider>
  );
}
