import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { IWikiItem } from '../../containers/Wiki/wiki';

interface IWikiCard {
  cardData: IWikiItem;
}

const WikiCard = ({ cardData }: IWikiCard) => {
  return (
    <Card sx={{ display: 'flex' }}>
      <Stack justifyContent={'space-between'} flexGrow={1}>
        <CardContent>
          <Typography variant={'h6'} pb={cardData.description ? 1 : 0}>
            {cardData.title}
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{ __html: cardData.description }}
          />
        </CardContent>
        <CardActions>
          <Stack direction={'row'} flexWrap={'wrap'}>
            {cardData.links.map((link, index) => (
              <Button
                component={'a'}
                href={link.url}
                target={'_blank'}
                key={index}
              >
                {link.label}
              </Button>
            ))}
          </Stack>
        </CardActions>
      </Stack>
    </Card>
  );
};

export default WikiCard;
